import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BusinessSector } from '@parashift/shared/models';

@Component({
  selector: 'business-sector-badges',
  templateUrl: './business-sector-badges.component.html',
  standalone: true,
  imports: [NgFor, TooltipModule, FaIconComponent]
})
export class BusinessSectorBadgesComponent {
  @Input() businessSectors: BusinessSector[];
}
