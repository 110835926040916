import { Coordinates } from './interfaces/coordinates.interface';
import { isNumber } from './is-number';

export function validCoordinates(coordinates: Coordinates | Partial<Coordinates>): coordinates is Coordinates {
  return coordinates
    && isNumber(coordinates.top)
    && isNumber(coordinates.right)
    && isNumber(coordinates.bottom)
    && isNumber(coordinates.left);
}
